import Vue from 'vue'
import LightBootstrap from './light-bootstrap-main'

import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from './store/index';
import router from './routes/router'
import PrimeVue from 'primevue/config';
import'primevue/resources/themes/saga-blue/theme.css';
import'primevue/resources/primevue.min.css';
import'primeicons/primeicons.css';
import sharedMethods from './sharedMethods';
import vSelectPage from 'v-selectpage';
import FileManager from 'laravel-file-manager'

require('@/store/subscriber');

Vue.use(LightBootstrap);
Vue.use(FileManager, {store});
Vue.use(VueAxios, axios);
Vue.use(PrimeVue);
Vue.use(vSelectPage, {
    // server side data loader
    dataLoad: function(vue, data, params){
        return new Promise((resolve, reject)=>{
            axios.post(url, params).then(resp => resolve(resp), resp => reject(resp))
        })
    }
});

Vue.axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

Vue.axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response.status === 401 && store.getters['auth/authenticated']) {
    store.dispatch("auth/handleUnAuthenticatedResponse").then(() => {
      router.push('/login');
    });
  }
  return Promise.reject(error);
});


Vue.mixin({
  methods: sharedMethods
})





store.dispatch("auth/attempt", localStorage.getItem('token')).then(() => {
  store.dispatch("global/index").then(() => {
    new Vue({
      el: '#app',
      render: h => h(App),
      router,
      store
    })
  });
});


