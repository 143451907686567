import DashboardLayout from "@/pages/layouts/DashboardLayout";

let virtualCourseRoutes = {
  path: '/virtual-courses',
  component: DashboardLayout,
  redirect: '/virtual-courses/list',
  children: [
    {
      path: 'list',
      name: 'Virtual Course List',
      component: () => import(/* webpackChunkName: "virtual-courses" */  '@/pages/virtualCourses/VirtualCourseList'),
      meta: {
        permissionsCodes: ['virtual-courses/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Virtual Course',
      component: () => import(/* webpackChunkName: "virtual-courses" */  '@/pages/virtualCourses/VirtualCourseForm'),
      meta: {
        permissionsCodes: ['virtual-courses/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Virtual Course',
      component: () => import(/* webpackChunkName: "virtual-courses" */  '@/pages/virtualCourses/VirtualCourseForm'),
      meta: {
        permissionsCodes: ['virtual-courses/update'],
      }
    }
  ]
}

export default virtualCourseRoutes;
