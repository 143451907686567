import DashboardLayout from '@/pages/layouts/DashboardLayout.vue'
import NotFound from '@/pages/NotFoundPage.vue'
import Overview from '@/pages/Overview.vue'
import Login from '@/pages/authPages/Login.vue';
import rolesRoutes from "@/routes/rolesRoutes";
import permissionsRoutes from "@/routes/permissionsRoutes";
import usersRoutes from "@/routes/usersRoutes";
import branchesRoutes from "@/routes/pages/branchesRoutes";
import pagesRoutes from "@/routes/pages/pagesRoutes";
import redirectionRoutes from "@/routes/pages/redirectionPagesRoutes";
import contentRoutes from "@/routes/pages/contentPagesRoutes";
import panelGroupsRoutes from "@/routes/panels/panelGroupsRoutes";
import panelRoutes from "@/routes/panels/panelRoutes";
import settingsRoutes from "@/routes/general/settingsRoutes";
import UpdatePassword from "@/pages/profile/UpdatePassword";
import UpdateProfile from "@/pages/profile/UpdateProfile";
import ForgetPassword from "@/pages/authPages/ForgetPassword";
import ResetPassword from "@/pages/authPages/ResetPassword";
import newsletterRoutes from "@/routes/general/newsletterRoutes";
import contactusRoutes from "./general/contactusRoutes";
import mediaInfoRoutes from "@/routes/general/mediaInfoRoutes";
import programOverViewRoutes from './programOverViewRoutes';
import forumRoutes from "./forums/forumRoutes";
import speakerRoutes from "./speakers/speakerRoutes";
import sessionRoutes from "./sessions/sessionRoutes";
import sessionDayRoutes from "./sessions/sessionDayRoutes";
import sessionTopicRoutes from "./sessions/sessionTopicRoutes";
import recordingRoutes from "./sessions/recordingRoutes";
import sessionDocumentRoutes from "./sessions/sessionDocumentRoutes";
import localHostRoutes from "./localHosts/localHostRoutes";
import organizerRoutes from "./organizers/organizerRoutes";
import partnerRoutes from "./partners/partnerRoutes";
import preWorkRoutes from "./preWorks/preWorkRoutes";
import virtualCourseRoutes from "./virtualCourses/virtualCourseRoutes";
import sponsorRoutes from "./sponsors/sponsorRoutes";
import practicalMattersRoutes  from "./general/practicalMattersRoutes"


let dashboardRoutes = {
  path: '/',
  component: DashboardLayout,
  redirect: '/overview',
  children: [
    {
      path: 'overview',
      name: 'Overview',
      component: Overview,
    },
    {
      path: 'change-my-password',
      name: 'Update Password',
      component: UpdatePassword,
    },
    {
      path: 'update-profile',
      name: 'Update Profile',
      component: UpdateProfile,
    },

  ],

};

let loginPage = {
  path: '/login',
  name: 'Login',
  component: Login
};
let forgetPasswordPage = {
  path: '/forget-password',
  name: 'Forget Password',
  component: ForgetPassword
};

let resetPassword = {
  path: '/reset-password/:token',
  name: 'Reset Password',
  component: ResetPassword
};

const routes = [
  {
    path: '/',
    redirect: '/overview'
  },
  loginPage,
  forgetPasswordPage,
  resetPassword,
  rolesRoutes,
  permissionsRoutes,
  redirectionRoutes,
  usersRoutes,
  branchesRoutes,
  contentRoutes,
  pagesRoutes,
  panelGroupsRoutes,
  panelRoutes,
  settingsRoutes,
  newsletterRoutes,
  contactusRoutes,
  dashboardRoutes,
  mediaInfoRoutes,
  programOverViewRoutes,
  forumRoutes,
  speakerRoutes,
  sessionRoutes,
  sessionDayRoutes,
  sessionTopicRoutes,
  recordingRoutes,
  sessionDocumentRoutes,
  localHostRoutes,
  organizerRoutes,
  partnerRoutes,
  preWorkRoutes,
  virtualCourseRoutes,
  sponsorRoutes,
  practicalMattersRoutes,
  {path: '*', name: 'Not Found', component: NotFound}
]

export default routes
