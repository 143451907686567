import DashboardLayout from "@/pages/layouts/DashboardLayout";

let organizerRoutes = {
  path: '/organizers',
  component: DashboardLayout,
  redirect: '/organizers/list',
  children: [
    {
      path: 'list',
      name: 'Organizers List',
      component: () => import(/* webpackChunkName: "organizers" */  '@/pages/organizers/OrganizerList'),
      meta: {
        permissionsCodes: ['organizers/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Organizer',
      component: () => import(/* webpackChunkName: "organizers" */  '@/pages/organizers/OrganizerForm'),
      meta: {
        permissionsCodes: ['organizers/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Organizer',
      component: () => import(/* webpackChunkName: "organizers" */  '@/pages/organizers/OrganizerForm'),
      meta: {
        permissionsCodes: ['organizers/update'],
      }
    }
  ]
}

export default organizerRoutes;
