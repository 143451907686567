import DashboardLayout from "@/pages/layouts/DashboardLayout";

let sessionRoutes = {
  path: '/sessions',
  component: DashboardLayout,
  redirect: '/sessions/list',
  children: [
    {
      path: 'list',
      name: 'Session List',
      component: () => import(/* webpackChunkName: "sessions" */  '@/pages/sessions/SessionList'),
      meta: {
        permissionsCodes: ['sessions/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Session',
      component: () => import(/* webpackChunkName: "sessions" */  '@/pages/sessions/SessionForm'),
      meta: {
        permissionsCodes: ['sessions/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Session',
      component: () => import(/* webpackChunkName: "sessions" */  '@/pages/sessions/SessionForm'),
      meta: {
        permissionsCodes: ['sessions/update'],
      }
    }
  ]
}

export default sessionRoutes;
