import DashboardLayout from "@/pages/layouts/DashboardLayout";

let localHostRoutes = {
  path: '/local-hosts',
  component: DashboardLayout,
  redirect: '/local-hosts/list',
  children: [
    {
      path: 'list',
      name: 'Local Hosts List',
      component: () => import(/* webpackChunkName: "local-hosts" */  '@/pages/localHosts/LocalHostList'),
      meta: {
        permissionsCodes: ['local-hosts/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Local Host',
      component: () => import(/* webpackChunkName: "local-hosts" */  '@/pages/localHosts/LocalHostForm'),
      meta: {
        permissionsCodes: ['local-hosts/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Local Host',
      component: () => import(/* webpackChunkName: "local-hosts" */  '@/pages/localHosts/LocalHostForm'),
      meta: {
        permissionsCodes: ['local-hosts/update'],
      }
    }
  ]
}

export default localHostRoutes;
