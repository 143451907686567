import DashboardLayout from "@/pages/layouts/DashboardLayout";

let programOverViewRoutes = {
  path: '/program-overview',
  component: DashboardLayout,
  redirect: '/program-overview/list',
  children: [
    {
      path: 'list',
      name: 'Program Overview List',
      component: ()=>import(/* webpackChunkName: "roles" */  '@/pages/programOverview/ProgramOverviewList'),
      meta: {
        permissionsCodes: ['program-overview/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Program Overview Item',
      component: ()=>import(/* webpackChunkName: "roles" */  '@/pages/programOverview/ProgramOverviewForm'),
      meta: {
        permissionsCodes: ['program-overview/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Program Overview Item',
      component: ()=>import(/* webpackChunkName: "roles" */  '@/pages/programOverview/ProgramOverviewForm'),
      meta: {
        permissionsCodes: ['program-overview/update'],
      }
    }
  ]
}

export default programOverViewRoutes;
