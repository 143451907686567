import DashboardLayout from "@/pages/layouts/DashboardLayout";

let speakerRoutes = {
  path: '/speakers',
  component: DashboardLayout,
  redirect: '/speakers/list',
  children: [
    {
      path: 'list',
      name: 'Speakers List',
      component: () => import(/* webpackChunkName: "speakers" */  '@/pages/speakers/SpeakerList'),
      meta: {
        permissionsCodes: ['speakers/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Speaker',
      component: () => import(/* webpackChunkName: "speakers" */  '@/pages/speakers/SpeakerForm'),
      meta: {
        permissionsCodes: ['speakers/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Speaker',
      component: () => import(/* webpackChunkName: "speakers" */  '@/pages/speakers/SpeakerForm'),
      meta: {
        permissionsCodes: ['speakers/update'],
      }
    }
  ]
}

export default speakerRoutes;
