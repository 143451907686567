import DashboardLayout from "@/pages/layouts/DashboardLayout";

let preWorkRoutes = {
  path: '/pre-works',
  component: DashboardLayout,
  redirect: '/pre-works/list',
  children: [
    {
      path: 'list',
      name: 'Pre-work List',
      component: () => import(/* webpackChunkName: "pre-works" */  '@/pages/preWorks/PreWorkList'),
      meta: {
        permissionsCodes: ['pre-works/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Pre-work',
      component: () => import(/* webpackChunkName: "pre-works" */  '@/pages/preWorks/PreWorkForm'),
      meta: {
        permissionsCodes: ['pre-works/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Pre-work',
      component: () => import(/* webpackChunkName: "pre-works" */  '@/pages/preWorks/PreWorkForm'),
      meta: {
        permissionsCodes: ['pre-works/update'],
      }
    }
  ]
}

export default preWorkRoutes;
